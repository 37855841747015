@use './typography';
@use './colors';

.buttons-container {
  display: flex;
  justify-content: space-between;
}
.arrow-back,
.arrow-forward {
  background-color: transparent;
  border: none;
  &::after {
    content: '';
    display: flex;
    background-image: url(https://cdn.aftenposten.no/vink/images/icons/arrows/arrow_blue.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 22px;
    height: 16px;
    margin-bottom: var(--space-4);
  }
  &:hover {
    cursor: pointer;
  }
  &:only-child {
    margin-left: auto;
  }
}
.arrow-back::after {
  transform: rotate(180deg);
}

.phone-front {
  .arrow-back,
  .arrow-forward {
    display: none;
  }
  .children {
    @include colors.snap-container(0, var(--space-4));
    article {
      @include colors.snap-item();
    }
  }
}

.guides-wrapper,
.restaurants-wrapper {
  margin: 0 auto;
  max-width: var(--max-width);
  width: 100%;
  padding: 40px 0 50px 16px;
  overflow: hidden;
  margin-bottom: 30px;
}

// guide specifics
.guides-wrapper {
  background-color: var(--c-gray);
}
.guides-title {
  @include typography.mabry(42px, 44px, 500, 0, var(--c-dark));
  margin-bottom: var(--space-18);
}
.guides-container {
  display: flex;
  overflow-x: hidden;
}
.teaser-widget {
  display: flex;
  flex-direction: column;
  margin-right: var(--space-4);
  width: 256px;

  .teaser-link {
    text-decoration: none;
  }
  .image {
    width: 256px;
    height: 177px;
    border-radius: 2px;
  }
  .title {
    margin-top: var(--space-3);
    @include typography.mabry(17px, 20px, 500, -0.2px, var(--c-dark));
  }
}

// restaurant specifics
.restaurants-wrapper {
  background-color: var(--c-yellow-bright);
  .teaser {
    &::before {
      content: '';
      width: 100%;
      height: 120px;
      position: absolute;
      border-radius: 2px;
      background: linear-gradient(
        180deg,
        rgba(0, 7, 36, 0.85) 0%,
        rgba(0, 7, 36, 0) 100%
      );
    }
    .image {
      border-radius: 2px;
    }
    .text-wrapper {
      background-color: transparent;
      position: absolute;
      top: 0;
      padding: var(--space-2);
    }
    .title {
      @include typography.mabry(15px, 18px, 500, -0.3px, var(--c-light));
    }
    .top-list-tag {
      display: none;
    }
  }
}
.restaurants-title {
  @include typography.mabry(42px, 44px, 500, 0, var(--c-yellow-text));
  margin-bottom: var(--space-14);
  text-transform: uppercase;
  max-width: 450px;
}

.phone-front {
  .restaurants-wrapper {
    padding-left: 0;
    .restaurants-title {
      margin-left: var(--space-4);
      word-wrap: break-word;
      margin-bottom: var(--space-12);
    }
    .teaser-list {
      width: 45vw;
      flex-shrink: 0;
      margin-right: 10px;
      &:first-child {
        margin-left: var(--space-4);
      }
      .title {
        font-size: 17px;
        line-height: 20px;
      }
    }
  }
}

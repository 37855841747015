@use '../typography.scss';
@import 'node_modules/@sch-inventory/advertory/styles/aftenposten/styles';

$container-padding: 32px;

.ad-container {
  background-color: #e9e9e9;
  margin: 50px 0;
  padding: $container-padding 0;
  position: relative;
  isolation: isolate;

  .ad {
    &-frontpage {
      padding: 0;
      width: fit-content;
      max-width: unset;

      &::before {
        padding: 0;
        color: #000724;
      }
    }

    &-article {
      padding: 0;
      width: fit-content;
      max-width: unset;

      &::before {
        content: 'Artikkelen fortsetter etter annonsen';
        position: absolute;
        width: 100%;
        top: calc(-1 * $container-padding);
        padding: 0;
        @include typography.graphik(17px, 25px, 400, 0, rgba(101, 101, 101, 1));
        font-style: italic;
      }
    }

    &.advertory-native-ad:before {
      display: none;
    }
  }

  &.ad-list:not(.ad-mobile) {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
  }

  &.ad-mobile {
    margin: 50px 0;
  }

  &.ad-fullwidth {
    width: 100%;

    > .ad-article::before {
      content: 'ANNONSE';
    }
  }

  .review-article {
    .ad-container {
      margin: 0;

      &.ad-mobile {
        margin: 0;
      }
    }
  }

  &.advertory-native-ad {
    background-color: transparent;
  }
}

.desktop-article-container,
.phone-article-container {
  .ad-container {
    background-color: transparent;
    margin: 0;

    &.ad-mobile {
      margin: 0;
    }
  }

  .review-article .ad-container.ad-fullwidth {
    margin-top: 0;
    padding: 30px 0 0;
  }
}

.tablet-article-container,
.tablet-front {
  .ad-container {
    margin: 50px 0;
  }
}

.desktop-front,
.phone-front {
  .ad-container {
    margin-top: 0;
  }
}

.ad {
  width: 100%;
  margin: 22px 0 16px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  text-align: center;
  min-height: 1px;
  min-width: 1px;
}

.ad:empty {
  visibility: hidden;
}

.ad:before {
  content: 'annonse';
  display: block;
  position: absolute;
  top: -15px;
  left: 0;
  margin: 0;
  padding: 0 16px;
  font-family: sans-serif;
  font-size: 9px;
  color: #979797;
  line-height: 1.6;
}

// override the following rule that comes from the package
// @media print {
//     [class*=advertory] {
//         display: none !important;
//     }
// }
@media print {
  .phone-article-container,
  .desktop-article-container {
    display: block !important;
  }
}

.desktop-front .tag-page-container {
  .advertory-aftenposten-native-ad-wrapper__article {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
  }
}

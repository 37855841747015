@use './typography';

.pwa-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--c-light);
  padding-top: var(--space-4) var(--space-4) var(--space-6);
  border-radius: var(--space-4) var(--space-4) 0 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 7, 36, 0.16);

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--space-6) var(--space-4) 0;
  }

  .title {
    @include typography.mabry(20px, 23px, 500, -0.2px, var(--c-dark));
  }

  .close {
    display: flex;
    align-items: center;
    @include typography.graphik(16px, 22px, 500, 0, var(--c-primary-2));
    background-color: transparent;
    border: none;

    &::after {
      content: '';
      display: block;
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/cross_extra_bold.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 16px;
      height: 16px;
      margin-left: var(--space-2);
    }
  }

  .info-container {
    display: flex;
    @include typography.graphik(17px, 25px, 400, 0, var(--c-dark));

    padding: var(--space-4) var(--space-4) var(--space-6);

    .app-icon {
      margin-right: var(--space-4);
    }

    .share-icon {
      display: inline-flex;
      transform: translateY(2px);
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: var(--space-2);
  }

  .pwa-button {
    padding: var(--space-2) var(--space-16);
    color: var(--c-light);
    background-color: var(--c-primary-2);

    &.white {
      color: var(--c-primary-2);
      background-color: var(--c-light);
      border: 1px solid var(--c-primary-2);
    }
  }
}

@use './typography.scss';
@use './colors.scss';
@import '../../../public-src/core/scss/helpers/mixins';

$grid-width-tablet: 768px;
$grid-width-web: 978px;

// The baseline is mobile first and classic braze cards — variant overrides are at the bottom of the file
.braze.content-card {
  --card-title-color: var(--c-primary-04);
  --card-description-color: var(--c-neutral-09);
  --card-bg-color: #fff;
  --card-border-color: rgba(0, 7, 36, 0.1);
  --action-bg-color: var(--c-primary-2);
  --action-bg-hover-color: #001752;

  &.classic-card-salg,
  &.captioned-image-salg {
    --action-bg-color: #1e1e1e;
    --action-bg-hover-color: #302f2f;
    --card-bg-color: #fff;
    --card-title-color: #fff;
    --card-description-color: #fff;
    --card-border-color: var(--card-bor-color);
  }

  .ab-feed {
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border: none;
  }

  .ab-feed .ab-card {
    margin: 16px 16px 24px;

    @include respond-to(tablet) {
      margin: 24px 0px;
    }
  }

  .ab-feed-buttons-wrapper {
    display: none;
  }

  .ab-feed .ab-card .ab-pinned-indicator,
  .ab-feed .ab-card .ab-unread-indicator,
  .ab-feed .ab-feed-body {
    padding: 0;
    border: none;
  }

  .ab-feed .ab-card .ab-unread-indicator {
    display: none;
  }

  .ab-feed .ab-feed-body .ab-card {
    width: unset;
    box-shadow: none;
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-8);

    background-color: var(--c-gray);
    border: none;
    border-radius: 2px;

    @include respond-to(tablet) {
      align-items: center;
      border: 0;
      border-radius: 2px;
      padding: 24px 0;
    }
  }

  .ab-feed .ab-card.ab-classic-card .ab-image-area {
    height: 150px;
    width: auto;
    flex-shrink: 0;
    position: static;
    padding: 0;

    > img {
      aspect-ratio: 1 / 1;
    }

    @include respond-to(tablet) {
      height: 94px;
      width: 94px;
    }
  }

  .ab-feed .ab-card.ab-classic-card .ab-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .ab-feed .ab-feed-body .ab-card .ab-title {
    @include typography.mabry(28px, 33px, 500, -0.2px, var(--c-dark));
    padding: 0;
  }

  .ab-feed .ab-card.ab-classic-card:not(.with-image) {
    margin-top: 0;
    padding-top: 16px;

    .ab-title {
      margin-right: 32px;
    }
  }

  .ab-feed .ab-card .ab-description,
  .ab-feed .ab-card.ab-classic-card.with-image .ab-description {
    color: #000;
    padding: 0;
    margin-top: var(--space-4);
    font-family: inherit;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;

    @include respond-to(tablet) {
      margin-top: var(--space-4);
    }
  }

  .ab-feed .ab-card .ab-url-area {
    margin-top: var(--space-6);

    @include respond-to(tablet) {
      margin-top: var(--space-6);
    }

    > a {
      display: inline-block;
      text-align: center;
      width: 100%;
      background-color: var(--action-bg-color);
      color: #fff;
      border-radius: 44px;
      padding: 11px 16px;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      line-height: 20px;

      &:hover {
        background-color: var(--action-bg-hover-color);
        text-decoration: none;
      }

      &:active {
        background-color: var(--c-dark);
      }
    }
  }

  .ab-feed .ab-card .ab-close-button {
    opacity: 1;
    z-index: 2;
    padding: var(--space-8);
    top: -14px;
    right: -22px;
    width: auto;
    height: auto;

    svg {
      fill: var(--c-primary-04);
      padding: 8px;
      width: 16px;
      min-width: 32px;
      border-radius: 4px;
    }

    &:active svg {
      background-color: rgba(0, 7, 36, 0.3);
    }

    @include respond-to(tablet) {
      padding: 8px;
      top: 22px;
      right: 0;
      min-width: 32px;

      svg {
        padding: 8px;
      }

      &:hover svg {
        background-color: rgba(0, 7, 36, 0.1);
      }
    }
  }

  // Variant override — captioned-image
  .ab-feed .ab-card.ab-captioned-image {
    --image-height: 150px;
    flex-direction: column;
    align-items: center;

    .ab-image-area {
      height: auto;
      max-height: 230px;
      width: auto;
      margin-top: 0;
      // padding-top: 16px;
      padding-top: 0;
      margin: auto;

      > img {
        aspect-ratio: 16 / 9;
        object-fit: contain;
        height: 100%;
      }
    }

    .ab-url-area {
      display: flex;
      margin-top: var(--space-4);

      > a {
        background-color: var(--action-bg-color);
        color: #fff;
        border-radius: 44px;
        padding: 11px 16px;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        line-height: 20px;

        &:hover {
          background-color: var(--action-bg-hover-color);
          text-decoration: none;
        }

        &:active {
          background-color: var(--c-dark);
        }
      }

      @include respond-to(tablet) {
        --image-height: 290px;
        gap: 0;
        flex-direction: row;
        flex-flow: row-reverse;

        .ab-card-body {
          padding: 48px 80px;
        }

        .ab-card-body .ab-title {
          margin-top: 0;
        }

        .ab-card-body .ab-description {
          margin-top: 24px;
          font-size: 1.125rem;
          line-height: 1.5rem;
        }
      }
    }
  }

  .ab-feed .ab-feed-body .ab-card {
    padding-top: 56px;
  }
}

.desktop-front .braze.content-card {
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto;

  .ab-feed .ab-card.ab-captioned-image {
    flex-direction: row;
    justify-content: start;
  }

  .ab-title {
    margin-right: 80px;
  }

  .ab-description {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .ab-feed .ab-card .ab-url-area > a {
    padding: 11px 56px;
  }

  .ab-feed .ab-card.ab-captioned-image .ab-image-area {
    margin: 0;
    height: 226px;

    > img {
      aspect-ratio: 16 /9;
    }
  }

  .ab-feed .ab-feed-body .ab-card {
    padding: 32px;
  }

  .ab-feed .ab-card .ab-close-button {
    top: 30px;
    right: 18px;
  }

  .ab-card.ab-classic-card {
    align-items: center;
    flex-direction: row;

    .ab-image-area {
      width: 150px;
      height: 150px;
    }
  }
}

@use './typography';

.teaser.feature {
  width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: end;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: var(--bottom-space);
  .wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: var(--max-width);
    text-align: center;
  }
  .text .kicker {
    margin-bottom: var(--space-4);
  }
  .title {
    @include typography.mabry(40px, 44px, 500);
    letter-spacing: -0.2px;
    margin-bottom: var(--space-6);
  }
  .feature-description {
    @include typography.graphik(18px, 26px, 400);
  }
  .image {
    width: 536px;
    height: 357px;
    border-radius: var(--border-radius);
    margin: 80px 0 80px 118px;
  }
  .not-a-button {
    display: inline-flex;
    margin-top: var(--space-8);
    @include typography.not-a-button();
  }

  &.blue {
    background-color: var(--c-blue-bright);
  }
  &.pink {
    background-color: var(--c-pink-bright);
  }
  &.green {
    background-color: var(--c-green-bring);
  }
  &.yellow {
    background-color: var(--c-yellow-bright);
  }
  &.godt {
    background-color: var(--c-godt-bright);
  }

  &.illustration {
    border: none;
    .wrapper {
      text-align: initial;
      max-width: 723px;
      align-items: flex-start;
    }
    .title {
      color: var(--c-primary-2);
    }
    .image {
      width: 177px;
      height: auto;
      margin: 23px 0 0 102px;
    }
  }
}

// mobile
.phone-front .teaser.feature {
  margin: 0 0 var(--bottom-space-small) 0;
  .wrapper {
    width: 100%;
    flex-direction: column;
    padding: var(--space-8) var(--space-6);
    .title {
      font-size: 40px;
      line-height: 42px;
    }
  }
  .text {
    margin-bottom: var(--space-10);
  }
  .feature-description {
    font-size: 17px;
    line-height: 25px;
  }
  .image {
    margin: 0;
    width: 100%;
    height: auto;
  }
  .not-a-button {
    display: none;
  }

  &.illustration {
    .wrapper {
      align-items: center;
      text-align: center;
      flex-direction: column-reverse;
      padding: 0 var(--space-6);
    }
    .text {
      margin-bottom: 0;
      padding: var(--space-6) 0 0 0;
    }
    .image {
      width: 121px;
    }
    .not-a-button {
      display: inline-flex;
    }
  }
}

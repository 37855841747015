@use './typography';

$listItemSize: 50px;
.overlay.save-overlay {
  .modal {
    width: auto;
    padding: var(--space-10);
    min-width: 500px;
  }

  .list-options {
    display: flex;
    flex-direction: row;
    max-width: 485px;
    max-height: 485px;
    overflow: scroll;
    flex-direction: column;

    img {
      width: $listItemSize;
      height: $listItemSize;
      border-radius: 4px;
    }
    .list-button {
      display: flex;
      align-items: center;
      padding: var(--space-4);
      border-bottom: 1px solid var(--c-gray);

      &.save-success {
        background-color: var(--c-green-bright);
      }
    }
  }

  .saved-list-name {
    @include typography.graphik(17px, 25px, 600, 0, var(--c-dark));
    margin-left: var(--space-3);
    margin-right: var(--space-2);
  }
  .saved-items-count {
    @include typography.graphik(12px, 14px, 400, 0, var(--c-dark-gray));
    margin-top: var(--space-1);
  }
  .already-saved {
    margin-left: auto;
    width: 22px;
    height: 22px;
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .no-image-placeholder {
    display: flex;
    margin-bottom: var(--space-2);
    &::after {
      content: '';
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/no_save_icon.svg');
      width: $listItemSize;
      height: $listItemSize;
      background-size: contain;
      border-radius: var(--border-radius);
    }
  }

  .overlay-toast {
    position: absolute;
    bottom: var(--space-4);
    margin-top: var(--space-3);
    background-color: var(--c-green-bright);
    color: var(--c-green-text);
    padding: var(--space-1);
    border-radius: 2px;
    width: calc(100% - var(--space-10));
    text-align: center;
  }
}

.phone-front,
.guide-article.phone,
.review-article.mobile-article {
  .overlay.save-overlay {
    .modal {
      min-width: auto;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: none;
      top: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0;
      .list-options {
        overflow-x: auto;
        flex-wrap: nowrap;
        max-height: 50vh;
      }
    }

    .create-list-input {
      padding: 0 var(--space-4);

      input {
        max-width: 80%;
        font-size: 18px;
      }
    }
    .overlay-toast {
      bottom: var(--space-2);
    }
  }
}
